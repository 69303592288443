<template lang="pug">
  div
    template(v-if="!loading")
      v-card.mb-2(
        v-for="(item, index) in ingredients"
        :key="index")
        .list-1
          .list-1__wrapper
            .list-1__qr-code
              qr-code-dropdown(
                :name="item.name"
                :url="item.qr_code")
            .list-1__subtitle {{ item.name }}
            .list-1__settings
              v-btn(
                icon
                v-if="'Technologist' === user.user.role"
                @click="openEdit(item)")
                  icon-gear.list-1__icon
          .list-1__item
            .list-1__item-label {{ 'pages.storage.remainder_day_mobile' | translate }}
            .list-1__item-value {{ item.remainder_day }}
          .list-1__item
            .list-1__item-label {{ 'pages.storage.weight_per_day' | translate }} {{ 'switch_days.one_per_day' | translate }} [{{ 'units.kg' | translate }}]
            .list-1__item-value {{ item.weight_per_day | rounding }}
          .list-1__item
            .list-1__item-label {{ 'pages.storage.remainder_storage_mobile' | translate }} [{{ 'units.kg' | translate }}]
            .list-1__item-value {{ item.remainder_weight }}
          .list-1__item
            .list-1__item-label {{ 'base.cost' | translate }}
            .list-1__item-value {{ item.cost }}

      v-table-body(
        v-if="!loading"
        outsideTable
        :empty="!ingredients.length")

    template(v-else)
      v-card.mb-2(v-for="i in 5" :key="i")
        .list-1
          .list-1__title
            .list-1__title-skeleton
          .list-1__item(v-for="j in 4" :key="`${i}-${j}`")
            .list-1__item-skeleton

    v-dialog(
      max-width="348"
      v-model="editModal.show")
      settings-modal(
        :item="editModal.item"
        @close="editModal.show = false")

    template(
      v-if="!loading && showPagination")
      v-paginate(
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler")

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import IconGear from '@/components/icons/IconGear'
import QrCodeDropdown from '@/components/common/qr-code-dropdown/QrCodeDropdown'
import SettingsModal from './modals/SettingsModal'

import paginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'IngredientsTableMobile',

  mixins: [paginationMixin],

  components: {
    IconGear,
    QrCodeDropdown,
    SettingsModal
  },

  data: () => ({
    editModal: {
      item: null,
      show: false
    }
  }),

  mounted () {
    this.setupPagination(this.fetchIngredients)
  },

  methods: {
    ...mapActions(['fetchIngredients']),

    openEdit (item) {
      this.editModal.item = item
      this.editModal.show = true
    }
  },

  computed: {
    ...mapGetters([
      'ingredients',
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
</style>
